<template>
  <base-page class="error-activation">
    Activation failed...
  </base-page>
</template>

<script>
export default {

};
</script>
